.container {
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.productContainer {
  padding: 1rem;
  border-radius: 1rem;
  border: 2px solid #f7f7f7;
  max-width: 330px;
  align-self: stretch;
}

@media (min-width: 800px) {
  .container {
    flex-direction: row;
    gap: 1.5vw;
    width: 100%;
  }
}

.productNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.productName {
  color: #495e85;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.productDescription {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.productPriceContainer {
  display: flex;
  align-items: baseline;
  column-gap: 0.25rem;
}

.productPriceValue {
  color: #495e85;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.025em;
}

.productPriceCurrency {
  text-transform: uppercase;
}

.productPriceInterval {
  color: #d1d5db;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.productBuyButton {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  margin-top: 1rem;
  color: #fff;
  background-color: #f77b13;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 0.375rem;
  border: unset;
  width: 100%;
  cursor: pointer;
}

.productBuyButton:hover {
  background-color: #f7d2b3;
}

.productMetaContainer {
  margin: 0;
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0;
  /* color: #d1d5db; */
}

.productMetaItem {
  display: flex;
  column-gap: 0.75rem;
  align-items: flex-start;
}

.productMetaItemBullet {
  flex: none;
  width: 0.875rem;
  height: 0.875rem;
  margin-top: 0.1em;
}
