@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

a,
a:visited {
  color: #1b3667;
}

a:hover {
  color: #f77b13;
}

.circle-outer {
  background: #edeff3;
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  position: absolute;
  right: -350px;
  top: -400px;
  bottom: -400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  z-index: -9999;
}

.bigger {
  width: 1600px;
  height: 1600px;
}

.circle-inner {
  display: flex;
  background: #dde0e8;
  width: 800px;
  height: 800px;
  border-radius: 50%;
}

.loadingSpinner {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}
.loadingSpinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 3px;
  border: 3px solid #1b3667;
  border-radius: 50%;
  animation: loadingSpinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1b3667 transparent transparent transparent;
}
.loadingSpinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingSpinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingSpinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loadingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes szh-menu-show {
  from {
    opacity: 0;
  }
}

@keyframes szh-menu-hide {
  to {
    opacity: 0;
  }
}

@keyframes szh-menu-show {
  from {
    opacity: 0;
  }
}
@keyframes szh-menu-hide {
  to {
    opacity: 0;
  }
}

.szh-menu--state-opening {
  animation: szh-menu-show 0.15s ease-out;
}
.szh-menu--state-closing {
  animation: szh-menu-hide 0.2s ease-out forwards;
}

.szh-menu__item {
  padding: 0 !important;
}

.szh-menu__item--hover {
  background-color: #eeeeee;
}

.react-micro-modal--portal > div > div {
  z-index: 2;
}
