.container {
  display: flex;
  justify-content: center;
}

.radioGroup {
  display: grid;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: center;
  border-radius: 9999px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 0.5rem;
  background-color: #f7f7f7;
  border: 1px solid #edeff3;
}

.radioItem {
  padding: 0.75rem 1.75rem;
  border-radius: 9999px;
  cursor: pointer;
  color: #495e85;
}

.radioItemChecked {
  background-color: #f77b13;
  color: white;

  background: linear-gradient(to bottom,#ff9500 0,#f57517 100%);
  border-radius: 25;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
