.loader-box {
  overflow: hidden;
  display: block;
}

.loader-box.big {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-box.big span {
  margin-top: 8px;
  font-size: 14px;
  color: #1b3667;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
}

.loader {
  font-size: 3px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2); /* 255,255,255 */
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid rgba(255, 255, 255);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: rotate 0.8s infinite linear;
  animation: rotate 0.8s infinite linear;
}

.loader.big {
  font-size: 4px;
  border-top: 1.1em solid rgba(27, 54, 103, 0.2);
  border-right: 1.1em solid rgba(27, 54, 103, 0.2);
  border-bottom: 1.1em solid rgba(27, 54, 103, 0.2);
  border-left: 1.1em solid rgba(27, 54, 103);
}

.loader.dark {
  border-top: 1.1em solid rgba(27, 54, 103, 0.2);
  border-right: 1.1em solid rgba(27, 54, 103, 0.2);
  border-bottom: 1.1em solid rgba(27, 54, 103, 0.2);
  border-left: 1.1em solid rgba(27, 54, 103);
}

.loader.inline {
  display: inline-block;
  font-size: 2px;
  vertical-align: middle;
  margin: 0 16px;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
