.container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 56rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.heading {
  color: #f77b13;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.subheading {
  margin-top: 0.5rem;
  color: #1b3667;
  font-size: 2.525rem;
  font-weight: 700;
}
